import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Landing from "./pages/Landing";
import ContactPage from "./pages/ContactPage";
import { Provider } from "./components/ui/provider";

function App() {
  return (
    <Provider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
